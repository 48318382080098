<template>
  <VDialog
    :value="getGlobalPopupState"
    width="100%"
    max-width="800px"
    @update:returnValue="setGlobalPopup(false)"
  >
    <VCard
      :img="bgImg"
      :dark="!!bgImg"
      :color="bgColor"
      class="mx-auto pa-6"
      height="90vh"
      max-height="70vh"
    >
      <VCardTitle class="">
        <VFlex xs12 mb-2 text-xs-center>
          <BaseSiteContent
            tag="h1"
            tag-class="hero-text m-1"
            content-key="global_popup_title"
            default-content="Thanksgiving Catering is Here."
          />
        </VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-4">
        <div class="text-xs-center">
          <BaseSiteContent
            tag="h2"
            tag-class="r-headline"
            content-key="global_popup_body"
            default-content="Check out the Thanksgiving Catering Menu"
          />
        </div>
        <div class="text-xs-center pa-4">
          <component
            :is="'BaseButton'"
            v-if="getSiteContents.global_popup_btn_text"
            color="bubs-purple"
            large
            class="ml-0"
            :to="getSiteContents.global_popup_btn_route"
            :href="getSiteContents.global_popup_btn_link"
          >
            {{ getSiteContents.global_popup_btn_text }}
          </component>
          <component
            :is="'BaseButton'"
            v-if="getSiteContents.global_popup_btn2_text"
            color="bubs-purple"
            large
            class="ml-0"
            :to="getSiteContents.global_popup_btn2_route"
            :href="getSiteContents.global_popup_btn2_link"
          >
            {{ getSiteContents.global_popup_btn2_text }}
          </component>
          <BaseButton
            class="ml-0 close-popup-btn"
            color="grey"
            absolute
            bottom
            right
            @click="setGlobalPopup(false)"
            >Close</BaseButton
          >
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'GlobalPopup',
  components: {
    VDialog,
    VCardText,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalPopupState']),
    ...mapGetters('site', [
      'getSiteTitle',
      'getSiteContentByKey',
      'getSiteContents',
    ]),
    bgImg() {
      let bgImg = this.getSiteContentByKey('global_popup_bg_img')
      return typeof bgImg === 'undefined' ? '' : bgImg
    },
    bgColor() {
      return this.bgImg.length ? 'grey darken-2' : 'white'
    },
  },
  methods: {
    ...mapActions('nav', ['setGlobalPopup']),
  },
}
</script>

<style lang="css">
.auth-modal {
  z-index: 930;
}
.v-btn--absolute.close-popup-btn {
  bottom: 10px !important;
}
</style>
